.invalid-input {
  border-color: var(--color-error) !important;
}
.text-error {
  color: var(--color-error) !important;
}

.textOnSurface {
  color: var(--color-on-surface)
}
.textOnSurfaceMuted {
  color: var(--color-on-surface-muted)
}
.primaryColor{
 color:  var(--color-primary);
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.prefix-icon {
  position: absolute;
  top: 0.9rem;
  left: 0.6rem;
}

#web-app .svg-background {
  background-color: var(--color-on-primary-muted);
  border-radius: 3rem;
  padding: 1rem;
}
#web-app .floatingLabel {
  position: absolute;
  left: 0.7rem;
  background-color: transparent;
  transform: translateY(0.2rem);
  transition: transform 0.4s ease, background-color 0.2s ease;
  z-index: 9;

  &.labelActive, &.floatingLabelWithValue {
    transform: translateY(-1.6rem);
    background: var(--color-surface);
    background-clip: content-box;
  }
}
// Styles for password fields
.password-field-icons {
  position: absolute;
  display: flex;
  align-items: center;
  top: .9rem;
  right: 2%;

  span {
    display: flex;

    &.password-field-error-icon {
      color: var(--color-error);
    }
    &.password-field-success-icon {
      color: var(--color-success);
    }
    &.password-field-eye-icon {
      color: var(--color-on-surface);
    }
  }

  mat-icon {
    height: 24px;
    width: 24px;
  }
}
.highlighted-password-rule {
  color: var(--color-success);
}
.highlighted-password-rule-error {
  color: var(--color-error);
}
