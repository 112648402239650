// Colors
$color-workflow-black: #131821;
$color-workflow-white: #ffffff;
// Greys
$color-workflow-lightergrey: #e3e3e3;
$color-workflow-lightgrey: #d2d6dd;
$color-workflow-darker-grey: #c5c5c5;
$color-workflow-grey: #6c7490;
$color-workflow-darkgrey: #3f4144;
$color-workflow-light-grey: #979797;
$color-workflow-border-grey: #dedede;
$color-workflow-soft-grey: #f5f5f5;
// Blues
$color-workflow-darkblue: #233048;
$color-workflow-blue: #0f172a;
$colour-workflow-lightblue: #49a9ff;
$color-workflow-lighterblue: #ebf1f8;
// Greens
$color-workflow-green: #00db3d;
$color-workflow-lightergreen: #deffe7;
// Other colours
$color-workflow-primary: $color-workflow-green;
$color-workflow-error: #d43939;
$color-workflow-success: $color-workflow-green;
$color-workflow-success-bg: $color-workflow-lightergreen;
$color-workflow-pink: #b71cff;
$color-workflow-positive: #142d53;
$color-workflow-negative: #ff6363;
$color-workflow-warning: #ea8000;
$color-workflow-link: #1870f3;
$color-workflow-note-info: #fff7cf;
// Backgrounds
$color-workflow-bg: $color-workflow-white;
$color-workflow-bg-secondary: $color-workflow-lightergrey;
$color-workflow-bg-header: $color-workflow-darkblue;
// Other usages
$color-workflow-text: $color-workflow-black;
$color-workflow-text-secondary: $color-workflow-grey;
$color-workflow-border: $color-workflow-darker-grey;
$color-workflow-active: $colour-workflow-lightblue;

$color-workflow-popup-nth-child: #fafafa;

// Finxone colour palette
$color-finxone-midnight: #0d173b;
$color-finxone-sky: #92e9fd;
$color-finxone-corn: #fce573;
$color-finxone-azure: #3674fd;
$color-finxone-fog: #f2f2f2;
$color-finxone-coal: #363740;
$color-finxone-purple: #803fcf;
$color-finxone-success: #00db3d;

// Percentages
$color-disable-percentage: 20%;

// Spacing
$grid-maxWidth: 120rem;
$grid-gutter: 2rem;
$spacing-unit: 0.5rem;
$border-radius: 1px;

// Fonts
$font-size: 1.6rem; // 16px
$font-color: $color-workflow-black;
$font-family: 'Mulish', sans-serif;

$progress-bar-widget-border-color: rgba(204, 204, 204, 1);

// Functions
@function spacing($multiplier) {
  @return $spacing-unit * $multiplier;
}
