@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~primeng/resources/themes/mdc-light-indigo/theme.css';
@import '~primeng/resources/primeng.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'styles/index';
@import './assets/styles/theme-styles.scss';
@import './assets/styles/skeleton-styles';

.pointer-events-none {
  pointer-events: unset;
}

:root {
  --palette-color-6: white;
}

// Stop gridster animations
gridster .gridster-column,
gridster .gridster-row,
gridster-item,
gridster-item.gridster-item-resizing,
gridster-item.gridster-item-moving {
  transition: unset !important;
}

html::-webkit-scrollbar,
#web-app::-webkit-scrollbar,
#web-app *::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent; /* make scrollbar transparent */
  -webkit-appearance: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  -webkit-overflow-scrolling: touch;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: unset;
  vertical-align: unset;
}

👈 html,
#web-app {
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--palette-color-6);
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  font-size: 1.6rem; // 16px
  line-height: 2.4rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#web-app .widget-padding {
  margin-bottom: 1.5rem;
}

#web-app {
  margin: 0;
  font-family: var(--font-family);
  overflow: auto;

  div#box-overlay {
    pointer-events: none;
  }

  h1#h1-box-overlay {
    pointer-events: none;
  }
}

#web-app ul {
  list-style: none;
}

#web-app .mat-typography p {
  margin-bottom: 0;
}

#web-app a {
  text-decoration: none;
  color: var(--palette-color-3);
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

#web-app li:hover {
  background-color: var(--palette-color-5);
}

#web-app .p-ink-active {
  animation: unset !important;
}

#web-app .p-ink {
  display: unset !important;
  position: unset !important;
  background: unset !important;
  border-radius: unset !important;
  transform: unset !important;
}

#web-app .sidebar {
  max-width: 250px;
}

#web-app .details {
  flex-grow: 1;
  margin: 20px;
}

/* Animate items as they're being sorted. */
#web-app .cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
#web-app .cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

#web-app .primary-transparent-btn {
  background: transparent;
  color: var(--palette-color-3);
  border: 1px solid var(--border-color-0);
  border-radius: 6px;

  &:hover {
    color: var(--palette-color-3);
    background-color: transparent;
    border-color: var(--border-color-0);
  }
}

#web-app .primary-button {
  background: var(--palette-color-3);
  color: var(--palette-color-5);
  font-size: 14px;
  border: 1px solid transparent;
  width: 100%;
  @include font-weight-normal;
  // box-shadow:
  //   0.2em 0.2em 0 0 $color2;
  transition: all 0.1s ease-in-out;

  &:hover {
    opacity: 0.87;
    // transform: translateY(0.2em) translateX(0.2em);
    // box-shadow: 0em 0em 0 0 $color2;
  }
}

#web-app .p-component.p-element {
  background-color: var(--palette-color-5);
  border-color: var(--border-color-1);
  color: var(--typography-color-1);
}

#web-app .p-dropdown.p-component {
  background-color: var(--palette-color-6);
  border-color: var(--border-color-1);
  color: var(--typography-color-2);
  padding: 0px;
}

#web-app .p-element.p-inputwrapper {
  border-radius: 5px;
  border-color: var(--border-color-1);
  color: var(--typography-color-2);
  padding: 0px;
  display: inline-block;
}

#web-app .p-element.p-dropdown-label {
  padding: 0.5rem;
  color: var(--typography-color-0);
}

#web-app .p-inputgroup {
  border-radius: 5px;
  background-color: var(--palette-color-5);
  border-color: var(--border-color-1);
  color: var(--typography-color-2);
}

#web-app .p-dropdown-items {
  background-color: var(--palette-color-6);
}

#web-app .p-autocomplete-items {
  background-color: var(--palette-color-6);
}

// beneficiary  css dropdown
#web-app .beneficiary-dropdown .p-dropdown-trigger {
  display: none !important;
}

#web-app .p-dropdown-trigger {
  color: var(--typography-color-1);
}

#web-app .beneficiary-dropdown {
  .p-dropdown-label {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--typography-color-1);
  }

  .remove-btn {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: var(--palette-color-3);
    width: max-content;
    height: 1.5rem;
    align-items: center;
  }

  .p-dropdown-items-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10.5px 0px;
    background: var(--palette-color-5);
    border: 1px solid var(--palette-color-5);
    border-radius: 6px;
    margin-left: -4px;

    .p-dropdown-item {
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: var(--typography-color-1) !important;
      margin: 0px;
      height: 30px !important;
      background: none !important;

      .option-name {
        color: var(--typography-color-1) !important;
      }
    }

    .p-dropdown-item.p-highlight {
      color: var(--typography-color-1) !important;
    }

    .p-dropdown-item.p-highlight .option-name {
      color: var(--typography-color-0) !important;
    }

    .p-dropdown-items {
      margin: 0;
      padding: 0;
      border: 1px solid var(--border-color-1);
    }
  }

  .p-dropdown:not(.p-disabled):hover {
    outline: none;
    border: 1px solid var(--border-color-0) !important;
    box-shadow: none;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: none;
    border: 1px solid var(--border-color-0) !important;
    box-shadow: none;
  }

  p-dropdown:focus {
    border: 1px solid var(--border-color-0) !important;
  }

  p-dropdown:focus-visible {
    border: 1px solid var(--border-color-0) !important;
  }
}

#web-app .p-dialog {
  margin-bottom: -7px !important;
}

#web-app .p-dialog-draggable .p-dialog-header {
  display: none !important;
}

#web-app .p-dialog-content {
  background-color: var(--palette-color-5) !important;
}

#web-app .p-dialog-header {
  background-color: var(--palette-color-5);
  color: var(--typography-color-0);
}

#web-app .p-dialog-footer {
  background-color: var(--palette-color-5) !important;
}

#web-app .p-button-label {
  color: var(--palette-color-3);
  font-family: var(--font-family);
}

#web-app .p-dropdown-label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--typography-color-1);
}

#web-app .p-dropdown-items-wrapper {
  display: flex;
  overscroll-behavior: none;
  flex-direction: column;
  background: var(--palette-color-5);
  border: 1px solid var(--palette-color-5);
  border-radius: 6px;

  .p-dropdown-item {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: inherit;
    line-height: 18px;
    color: var(--typography-color-0) !important;
    padding: 0.5rem;
    margin: 0px;
    height: 35px !important;
    background: none !important;
  }

  .p-dropdown-item.p-highlight {
    color: var(--typography-color-0) !important;
  }

  .p-dropdown-items {
    margin: 0;
    padding: 0;
  }
}

#web-app .p-dropdown:not(.p-disabled):hover {
  outline: none;
  border: 1px solid var(--border-color-1) !important;
  box-shadow: none;
}

#web-app .p-dropdown:not(.p-disabled).p-focus {
  outline: none;
  border: 1px solid var(--border-color-0) !important;
  box-shadow: none;
}

#web-app p-dropdown:focus {
  border: 1px solid var(--border-color-0) !important;
}

#web-app p-dropdown:focus-visible {
  border: 1px solid var(--border-color-0) !important;
}

#web-app .p-component * {
  color: inherit;
}

#web-app .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: var(--typography-color-0);
}

/// confirmation Popup design

#web-app .transfer-confirmation.p-dialog {
  background-color: var(--palette-color-5);
  border: 1px solid var(--border-color-1);
  border-radius: 6px;
  box-sizing: border-box !important;
  margin-left: 2em;
  margin-right: 2em;
  height: 223px;
  width: 337px;

  .p-dialog-header {
    background-color: var(--palette-color-6);
    padding-bottom: 0;

    h3 {
      text-align: center;
      float: left;
      width: 100%;
      font-size: 20px;
      font-family: var(--font-family);
      font-weight: 700;
      color: var(--palette-color-0);
    }
  }

  .p-dialog-content {
    background-color: var(--palette-color-5);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: var(--typography-color-0);

    .confrm-msg {
      color: var(--palette-color-1);
      float: left;
      text-align: center;
      font-size: 14px;
    }

    .text-bold {
      color: var(--typography-color-0);
      font-weight: bolder;
      font-size: 14px;
    }
  }

  .p-dialog-footer {
    background-color: var(--palette-color-6) !important;
    text-align: center;
    padding-bottom: 2rem !important;
    .btn-yes {
      width: 128px;
      height: 38px;
      background: var(--palette-color-3);
      border: 1px solid var(--border-color-1);
      border-radius: 6px;

      span {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: var(--palette-color-6);
      }
    }

    .btn-no {
      width: 117px;
      height: 38px;
      border: 1px solid var(--border-color-0);
      border-radius: 6px;
      background-color: var(--palette-color-6);
      span {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: var(--palette-color-3);
      }
    }
  }
}

//@at-root
#web-app .p-toast-message {
  margin-left: 4em !important;
}

#webapp .card-header {
  background-color: var(--typography-color-0);
}

#web-app .p-dialog-title,
#web-app .p-dialog-header-icons {
  display: none;
}

#web-app .p-dialog-content {
  overflow: hidden;
}

#web-app .otp-input-box {
  width: 39px;
  height: 44px;
  margin: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  padding: 0px !important;
  background: var(--palette-color-6);
  border: 1px solid var(--border-color-1);
  border-radius: 6px;
  /// text enter in input
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  color: var(--typography-color-0);

  &.disabled-input {
    pointer-events: none;
    border: 1px solid var(--border-color-2) !important;
    background: var(--palette-color-6);
    color: var(--typography-color-2);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--border-color-0);
  }

  &:focus-visible {
    border: 1px solid var(--border-color-0);
  }
}

#web-app .p-dialog-footer {
  padding: 1rem !important;
  background: var(--palette-color-6) !important;

  button {
    box-shadow: initial;
    border: 0px;
    display: inline-flex;
    width: auto;
  }

  .button-reset-text {
    background-color: transparent;
    text-decoration: underline;
  }

  .button-back-text {
    background-color: transparent;
    border: 0px;
  }
}

#web-app .p-dialog-content {
  background-color: var(--palette-color-6) !important;
}

#web-app .button-verify-text {
  color: var(--palette-color-6);
}

#web-app .otp-popup {
  border: none;

  .card-header {
    border: none;
  }
}

.bg-image {
  background: url('../../../assets/images/background-image.svg');
  box-shadow: inset 0 0 0 2000px rgba(var(--palette-color-6-rgb), 0.95);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: luminosity;
  background-color: currentColor;
}

#web-app .p-toast-message-content {
  color: var(--palette-color-3);
}

#web-app {
  p-toast.error {
    .p-component * {
      color: red !important;
      // color: var(--alerts-color-1) !important;
      background-color: #fff;
      text-transform: capitalize;
    }

    .p-toast .p-toast-message.p-toast-message-error {
      border: solid red;
      border-width: 0 0 0 6px;
      width: 99%;
      margin: 0 0 0 5px !important;
    }
  }

  p-toast {
    .p-component * {
      color: var(--alerts-color-1) !important;
      // color: var(--alerts-color-1) !important;
      background-color: #fff;
      text-transform: capitalize;
    }

    .p-toast {
      opacity: 1;
      width: 100% !important;
      left: 0px;
    }

    .p-toast .p-toast-message.p-toast-message-success {
      border: solid var(--alerts-color-1);
      border-width: 0 0 0 6px;
      width: 99%;
      /* float: left; */
      margin: 0 0 0 5px !important;
    }

    .p-toast-summary {
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }

    .p-toast-detail {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.justify-content-between {
  justify-content: space-between;
}

#web-app .add-beneficiary-dialog .p-dialog {
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 75%;
}

.img-fluid {
  height: 100%;
  max-width: 100%;
}

#web-app input:focus,
#web-app select:focus,
#web-app button:focus,
#web-app textarea:focus,
#web-app *[tabindex]:focus,
#web-app input:focus-visible {
  /*removes focus outline applied by native browsers*/
  outline: none;
}

.div-app-footer {
  padding-bottom: 2rem;
  position: fixed;
  z-index: 99999;
}

.btn-link {
  background: none;
  text-decoration: underline;
}

finx-input.flex {
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 33px !important;
}

finx-input {
  vertical-align: middle;
  width: 33px !important;
}

.no-increment-input {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.flexnot input {
  display: inherit !important;
}

.isRelative {
  position: relative;
}

.grid-style {
  display: grid;
  gap: 6px;
  grid-auto-rows: minmax(3.5vh, auto);
}

.bottom-popup {
  .nsm-dialog {
    left: 49% !important;
  }

  .nsm-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Iphone 14 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .section.main-finxone {
    // margin-top: 20px;
    margin: 28px 10px 10px 10px;
  }
}

/* Iphone 14 Pro */
@media only screen and (min-width: 428px) and (min-height: 926px) and (-webkit-min-device-pixel-ratio: 3) {
  .section.main-finxone {
    margin: 10% 10px 10px 10px;
  }

  .profile-section {
    margin-top: 0px !important;
  }
}

.gridster {
  &.fixed {
    overflow-x: hidden !important;
    display: contents;
  }
}

.app-header {
  z-index: 99999;
}

gridster-item {
  overflow: initial !important;
}

.blur-content {
  filter: blur(0.5rem);
}

.disable-widget {
  opacity: 0.3;
  pointer-events: none;
}

.custom-profile-section {
  gap: 20px;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-start !important;

  .avatar {
    svg {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background-color: var(--palette-color-0);
    }
  }

  .user-info {
    .user-details {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        margin-top: 3.5px;
        svg {
          width: 18px;
          height: 18px;
          margin-top: 3.5px;
        }
      }

      h2 {
        margin: 0 8px 0 0;
        font-size: 20px;
        font-weight: 700;
        color: #000b0c;
      }
    }
    small {
      font-size: 14px;
      color: #000b0c;
      font-weight: 400;
    }
  }
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  flex-direction: row !important;
  justify-content: flex-start !important;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: var(--typography-color-0) !important;
  padding: 0.5rem;
  margin: 0px;
  height: 35px !important;
  background: none !important;
}

.p-multiselect-panel .p-multiselect-header {
  display: none;
}
.p-checkbox .p-checkbox-box {
  background-color: var(--palette-color-6);
  border: 1px solid var(--border-color-1);
}
.p-checkbox:hover .p-checkbox-box {
  border: 1px solid var(--border-color-1);
}
.p-multiselect-items-wrapper {
  background-color: var(--palette-color-6);
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.p-multiselect {
  display: inline-block;
  border: none;
}
.p-multiselect:not(.p-disabled).p-focus {
  border-color: inherit !important;
  box-shadow: none !important;
}
#web-app .p-component {
  color: inherit;
  border-color: inherit;
  background-color: inherit;
  font-size: inherit;
  font-family: inherit;
  border-radius: inherit;
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead {
  border-color: inherit !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--palette-color-4) !important;
}

.webkit-tap-highlight-color-transparent {
  -webkit-tap-highlight-color: transparent !important;
}

.border-right-secondary {
  border-right: 1px solid var(--border-color-1);
}
.p-element .p-multiselect-label-container {
  display: flex;
  align-items: center;
}

:root {
  --font-sans: '';
}

:root {
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;
  --muted: 0 0% 96.1%;
  --muted-foreground: 0 0% 45.1%;
  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 0 0% 3.9%;
  --border: 0 0% 89.8%;
  --input: 0 0% 89.8%;
  --primary: 0 0% 9%;
  --primary-foreground: 0 0% 98%;
  --secondary: 0 0% 96.1%;
  --secondary-foreground: 0 0% 9%;
  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --ring: 0 0% 3.9%;
  --radius: 0.5rem;
}

.dark {
  --background: 0 0% 3.9%;
  --foreground: 0 0% 98%;
  --muted: 0 0% 14.9%;
  --muted-foreground: 0 0% 63.9%;
  --popover: 0 0% 3.9%;
  --popover-foreground: 0 0% 98%;
  --card: 0 0% 3.9%;
  --card-foreground: 0 0% 98%;
  --border: 0 0% 14.9%;
  --input: 0 0% 14.9%;
  --primary: 0 0% 98%;
  --primary-foreground: 0 0% 9%;
  --secondary: 0 0% 14.9%;
  --secondary-foreground: 0 0% 98%;
  --accent: 0 0% 14.9%;
  --accent-foreground: 0 0% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;
  --ring: 0 0% 83.1%;
}

/*
  Below css is used for finxone-select-dropdown component when append to body is enabled.
  File: libs/ui-components/src/lib/finxone-select-dropdown/finxone-select-dropdown.component.ts
  */
.finxone-select-dropdown {
  &.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--inputFieldIdleBackgroundColor);
    border-color: var(--inputFieldIdleBorderColor);
    border-top-width: var(--inputFieldBorderTopWidth);
    border-bottom-width: var(--inputFieldBorderBottomWidth);
    border-left-width: var(--inputFieldBorderLeftWidth);
    border-right-width: var(--inputFieldBorderRightWidth);
    max-height: 200px;
    overflow-y: auto;

    .dropdown-option {
      padding: 10px;
      display: flex;
      align-items: inherit;
      cursor: pointer;
      border: 1px solid transparent;

      &:hover {
        color: var(--inputFieldActiveTextColor);
      }

      input[type='checkbox'] {
        margin-right: 10px;
      }
    }
  }
}
